import '../sass/style.scss';
import * as $ from 'jquery';
import 'slick-carousel';
import 'bootstrap';
import '../../node_modules/lightgallery/dist/js/lightgallery-all';
import '../../node_modules/typeahead.js/dist/typeahead.bundle.min.js';

$('.baner-items').slick({
    dots: true,
    infinite: true,
    speed: 1000,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover:false
});

$('.home .slider__group').slick({
    dots: true,
    arrows: true,
    infinite: true,
    speed: 1000,
    cssEase: 'linear',
    autoplay: false,
    autoplaySpeed: 3000,
    centerPadding: "0px",
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1
          }
        }
    ]
});

$('.home .blog__list').slick({
    dots: true,
    infinite: true,
    speed: 700,
    cssEase: 'linear',
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    pauseOnHover:false,
    responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1
          }
        }
    ]
});

function fixedMenu() {
    var height = $(window).scrollTop();
    if (height > 200) {
        $('.home #header').addClass('header--black');
    }
    else {
        $('.home #header').removeClass('header--black');
    }
}

$(function () {
    var mainPage = document.getElementsByTagName('body');
    if (mainPage.length > 0) {
        fixedMenu();
        $(window).scroll(function () {
            fixedMenu();
        });
    }

    if ($('.alert-login').length) {
        setTimeout(function(){
            $('.alert-login').fadeOut('500');
        }, 5000);
    }
});

// open/close search box
$(function(){
    $('#openSearch').on('click', function(){
        $('.search-box').addClass('search-box--show');
    });
    $('#closeSearch').on('click', function(){
        $('.search-box').removeClass('search-box--show');
    });
});

// dropdown search menu
$('.search-box__item .dropdown').click(function () {
    $(this).attr('tabindex', 1).focus();
    $(this).toggleClass('active');
    $(this).find('.dropdown-menu').slideToggle(300);
});

$('.search-box__item .dropdown').focusout(function () {
    $(this).removeClass('active');
    $(this).find('.dropdown-menu').slideUp(300);
});

$('.search-box__item .dropdown .dropdown-menu li').click(function () {
    $(this).parents('.dropdown').find('span').text($(this).text());
    $(this).parents('.dropdown').find('input').attr('value', $(this).attr('id'));
});

$('.search-box__item .dropdown-menu li').click(function () {
    var input = '<strong>' + $(this).parents('.dropdown').find('input').val() + '</strong>',
      msg = '<span class="msg">Hidden input value: ';
    $('.msg').html(msg + input + '</span>');
});

// smooth scroll
$(".baner__body a[href^='#']").on('click', function(e) {

    // prevent default anchor click behavior
    e.preventDefault();
 
    // store hash
    var hash = this.hash;
 
    // animate
    $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 900, function(){
 
        // when done, add hash to url
        // (default click behaviour)
        window.location.hash = hash;
      });
 
 });

 // offer filter 
 $(function(){
    $('#openSearchList').on('click', function() {
        $('#openSearch').click(); 
     });
    $('#sort-offer-button').click(function(){
      $('#sort-offer-menu').toggleClass('dblock');
    });

  // single offer full with description

    $('#moreOffer').click(function(){
        $('#offerDescription').addClass('offer__absolute');
        $('#closeOfferDescription').addClass('dblock');
        $('.full-text').addClass('dblock');
        $('#moreOffer').hide();
    });
    $('#closeOfferDescription').click(function(){
        $('#offerDescription').removeClass('offer__absolute');
        $('#closeOfferDescription').removeClass('dblock');
        $('.full-text').removeClass('dblock');
        $('#moreOffer').show();
    });


  // offer gallery

    // $('.thumbnailLink img').click(function() {
    //     var imgno = $(this).data('imgno');
    //     console.log(imgno);
    //     $('#lightgallery').data('imgno', imgno);
    //     $('#lightgallery a:nth-child(1)').attr('href', $(this).attr('src'))
    //     $('.offer-gallery__item:nth-child(1)').attr('src', $(this).attr('src')); 
    // }); 



    $('.slick-list').lightGallery({
        selector: '.slick-slide:not(.slick-cloned) .thumbnailLink',
        thumbnail: true,
        animateThumb: true,
        autoplay: true,
        pager: true,
        hash: true,
        autoplayControls: true,
        share: false,
        rotate: false,
        download: false,
        flipHorizontal: false,
        flipVertical: false,
        fullScreen: false,
        actualSize: false,
    });

    $('#lightgallery').click(function() {
        $('.slick-list .slick-slide:not(.slick-cloned)').find('img[data-imgno="0"]').click();
    });


    $(document).on('click', '#get-offer-pdf', function (event) {
        var location = $(event.target).attr('data-loc');
        if (typeof location == 'undefined') {
            location = $(event.target).parents('[data-loc]').attr('data-loc');
        }
        document.location.href = location;
    });

    $("#kontakt-form-link").click(function() {  
        $("html, body").animate({
            scrollTop: $('.box-contact').offset().top - 100
        }, 1000);
    });

    $('[data-action=share]').click(function(){
        $('.share-box').toggle();
    });
});

$('#thumbnailsList').slick({
    slidesToShow: 4,
    slideToScroll: 1,
    arrows: true,
    infinite: true,
    speed: 1000,
    dots: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          prevArrow: '',
          nextArrow: ''
        }
      }
    ]
  });

  
  $(document).ready(function(){
    
    /* FORMULARZ KONTAKTOWY */
    $('body').on({
        click: function(){  
            var handler = $(this).parents('.globalForm').data('handler');
            var formError = 0;
            var globalForm = $(this).parents('.globalForm');
            
            $(this).parents('.globalForm').find('.formField').each(function(){
                if($(this).hasClass('required') && $(this).val().trim() === ''){
                    $(this).parents('.formRow').addClass('has-error');
                    formError = 1;
                }
                else if($(this).hasClass('email') && !validateEmail($(this).val())){
                    $(this).parents('.formRow').addClass('has-error');
                    formError = 1;
                }
                else if($(this).is(':checkbox') && $(this).hasClass('required') && !$(this).prop('checked')){
                    $(this).parents('.formRow').addClass('has-error');
                    formError = 1;
                }
                else{
                    $(this).parents('.formRow').removeClass('has-error');
                }
            });   
            
            if(!formError)
            {
                $.ajax({
                    type: 'POST',
                    url: handler,
                    cache: false,
                    data: $(this).parents('form').serialize(),
                    dataType: 'json',
                    success: function(response) {
                        if (response.status) {
                            $(globalForm).find('form input:not(.submit)').val('');
                            $(globalForm).find('.mail-message-feedback').removeClass('form-error').text(response.message).show();
                        } else {
                            $(globalForm).find('.mail-message-feedback').addClass('form-error').text(response.message).show();
                        }
                    }
                });

            } else {
                var messageText = 'Uzupełnij pola';
                if ($('html').attr('lang') == 'en') {
                    messageText = 'Fill the fields';
                }
                $(globalForm).find('.mail-message-feedback').addClass('form-error').text(messageText).show();
            }
            return false;
        }
    },'.globalForm .submit');

});

function validateEmail($email) {
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    if(emailReg.test( $email ) ) {
      return true;
    } else {
      return false;
    }
}


$(document).ready(function(){
    $("#showHideOfferDescription").click(function(){

        var description = $("#offer-content__description-description").attr('class');
        $("#offer-content__description-description").toggleClass('hide', 1000, 'easeOutSine');
        $("#offer-content__description-description_hide").toggleClass('hide_description', 1000, 'easeOutSine');
  
        if(description == 'hide')
        {
            $("#showHideOfferDescription").html('Ukryj opis');

        }
        else 
        {
            $("#showHideOfferDescription").html('Pokaż opis');

        }
        $(window).scrollTop($('.description-body').offset().top);
    });

    /* ----------------------- handle cookies  ---------------------------------- */
    $("#cookies-info span.cookies-ok").click(function() {
        var date = new Date();
        date.setTime(date.getTime()+(180*24*60*60*1000));
        document.cookie = "cookie_message_shown=1;expires="+date.toGMTString()+";path=/;";
        $("#cookies-info").fadeOut();
        return false;
    });
});


     /*

     * SORTOWANIE LISTY OFERT

     */

    $(".offers-list__filter .dropdown-menu a").click(function(e){

        e.preventDefault();

        $('#hidSort').val($(this).data("id"));

        $('#form_list_sort').submit();

    });


    $(document).ready(function(){
        $('#projectionArea').hide();
        $('#mapArea').hide();
        
        $("#toggleProjectionArea").click(function(){
            $(".area-map__icon.first").toggleClass('btn-clicked');
            $('#projectionArea').toggle("slide");
        });
        $("#toggleMapArea").click(function(){
            $(".area-map__icon.second").toggleClass('btn-clicked');
            $('#mapArea').toggle("slide");
        });
        
        refreshLocationsList();
    });

    function refreshLocationsList() {
        var locationListUrl = "handler/getlocationlist";
      
        // if(typeof investmentSearch != 'undefined') {
        //   locationListUrl += '0/1';
        // }
      
        $.ajax({
            method: "POST",
            url: locationListUrl,
            data: {
                "_token": $('meta[name="csrf-token"]').attr('content'),
            },
            dataType: 'json',
        success: function(locationTable){
      
            var substringMatcher = function(strs) {
                return function findMatches(q, cb) {
                    var matches, substringRegex;
                    matches = [];
      
                    let term = q;
                    let regTerm = term.replace(/,/g, '');
                    regTerm = regTerm.replace(/ /g, '(.*)');
                    if(regTerm.substr(0, -4) === '(.*)')
                    {
                        regTerm = regTerm.substr(0, regTerm.length - 4);
                    }
                    let substrRegex = new RegExp(regTerm, "i");
                    $.each(strs, function(i, str) {
                        if (substrRegex.test(str)) {
                            matches.push(str);
                        }
                    });
      
                    cb(matches);
                };
            };
      
            $('#searchForm').submit(function(){
                if ($('.tt-menu').is(':visible')){
                    var textSplited = $('.tt-suggestion').first().html().split('strong');
      
                    var text = [];
                    text.push(textSplited[0].substr(0, textSplited[0].indexOf('<')));
                    text.push(textSplited[1].substr(textSplited[1].indexOf('>')+1, textSplited[1].indexOf('<')-textSplited[1].indexOf('>')-1));
                    text.push(textSplited[2].substr(textSplited[2].indexOf('>')+1));
      
                    $('.searchLocation').val(text.join(''));
                }
            });
      
            $('.searchLocation').typeahead({
                hint: false,
                highlight: true,
                minLength: 1
            },
            {
                name: 'states',
                source: substringMatcher(locationTable),
                limit: 100
            });
      
            $('.searchLocation').bind('typeahead:close', function(ev, suggestion) {
                if($(this).val()) {
                    let searchText = $(this).val().trim();
                    searchText = searchText.replace(/,/g,'');
      
                    let pattern = '^'+searchText+'(.*)';
                    let queryRegex = new RegExp(pattern, 'i');
                    for(let i = 0; i < locationTable.length; i++)
                    {
                        if(locationTable[i].match(queryRegex))
                        {
                            $(this).val(locationTable[i]);
                            $(this).typeahead('val', $(this).val());
                            return true;
                        }
                    }
      
                    let searchTextArray = searchText.split(' ');
                    pattern = searchTextArray.join('(.*)');
                    queryRegex = new RegExp(pattern, 'i');
                    for(let i = 0; i < locationTable.length; i++)
                    {
                        if(locationTable[i].match(queryRegex))
                        {
                            $(this).val(locationTable[i]);
                            $(this).typeahead('val', $(this).val());
                            return true;
                        }
                    }
                }
            });
          }
        });
      }